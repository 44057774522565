import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'components/Link';
import Router from 'next/router';
import {
    clearHomeAuctionData,
    getAuctionsEndingSoon,
    getMustKnowNotification,
    getMustKnowNotifications,
    getTopAuctions,
    searchLiveAuctions,
    setDashboardPage,
    triggerHomepageApis,
} from 'actions';
import AppPromoHero from 'components/Homepage/AppPromo';
import MustKnow from 'components/Homepage/MustKnow';
import TopSearch from 'components/Auctions/TopSearchForm';
import Blurb from 'components/partials/Blurb';
import TrustPilot from 'components/partials/TrustPilot';
import AuctionTile from 'components/Auctions/AuctionTile';
import Carousel from 'components/partials/Carousel/Carousel';
import { Loading } from 'components/Loading';
import DoRedirect from 'components/Page/DoRedirect';
import HomepageSchema from 'components/Schemas/HomepageSchema';
import AuctionSearchSchema from 'components/Schemas/AuctionSearchSchema';
import { trigger_cancel } from 'services/BaseService';
import 'styles/components/Main.less';
import 'styles/pages/homepage.scss';

const home_xxs = '/static/images/home/storage-unit-illustration.svg';
const home_xs = '/static/images/home/storage-unit-illustration.svg';
const home_sm = '/static/images/home/storage-unit-illustration.svg';
const home_md = '/static/images/home/storage-unit-illustration.svg';
const home_lg = '/static/images/home/storage-unit-illustration.svg';

const su_xxs = '/static/images/home/su-xxs.jpg';
const su_xs = '/static/images/home/su-xs.jpg';
const su_sm = '/static/images/home/su-sm.jpg';
const su_md = '/static/images/home/su-md.jpg';
const su_lg = '/static/images/home/su-lg.jpg';

const blurb1Image = '/static/images/home/blurb_1.jpeg';
const blurb2Image = '/static/images/home/blurb_2.jpeg';
const blurb3Image = '/static/images/home/blurb_3.jpg';

const step_1 = '/static/images/home/hunt.svg';
const step_2 = '/static/images/home/gavel.svg';
const step_3 = '/static/images/home/win.svg';
const step_4 = '/static/images/home/profit.svg';

const mk_xxs = '/static/images/home/MK_xxs.jpg';
const mk_xs = '/static/images/home/MK_xs.jpg';
const mk_sm = '/static/images/home/MK_sm.jpg';
const mk_md = '/static/images/home/MK_md.jpg';
const mk_lg = '/static/images/home/MK_lg.jpg';

class Index extends Component {
    static async getInitialProps({ store: { dispatch } }) {
        await Promise.all([
            dispatch(setDashboardPage({ title: false, menuEnum: false, pageAction: false })),
        ]);

        const pageData = {
            title: undefined,
            marginBottom: '',
            h1: undefined,
            description: 'America\'s #1 self storage auction directory. Offering real time auction listings, alerts, tools, how-to resources and more. Register to start bidding & winning!',
        };

        return { pageData };
    }

    selectAuctionTypeOptions = [
        { id: 1, text: 'Auctions Ending Soon' },
        { id: 2, text: 'Top Auctions' },
    ];

    state = {
        heroImages: [home_xxs, home_xs, home_sm, home_md, home_lg],
        carouselBackground: [mk_xxs, mk_xs, mk_sm, mk_md, mk_lg],
        readyToStartBackground: [su_xxs, su_xs, su_sm, su_md, su_lg],
        image: [],
        selected_auction_type: '1',
        value: 0,
        message: 'default click state',
        howItWorks: [
            {
                image: step_1,
                title: 'Step 1: Hunt',
                content: `You’ve come to the right place! Search near and far through thousands
                    of storage auctions to find unexpected treasures.`,
            },
            {
                image: step_2,
                title: 'Step 2: Bid',
                content: <div>
                    Have your eyes set on some goodies? As a member, placing a bid on an auction unit has never been easier.
                    {' '}
                    <Link to="/register">Create a free account</Link>
                    {' '}
                    to start bidding!
                </div>,
            },
            {
                image: step_3,
                title: 'Step 3: Win',
                content: 'Time to celebrate! Get in touch with the storage facility and then head over to pay for and claim your winnings!',
            },
            {
                image: step_4,
                title: 'Step 4: Profit',
                content: <div>
                    Need ideas for what to do with all of those new treasures?
                    {' '}
                    <a
                        href="https://blog.storagetreasures.com/category/tips-tricks/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                    Learn tips and tricks
                    </a>
                    {' '}
                    from our resident experts to start making some cash!
                </div>,
            },
        ],
        blurbs: [
            {
                image: blurb1Image,
                title: 'First of Our Kind',
                content: 'Since 2011, StorageTreasures has led the charge revolutionizing the storage lien sale. Over the years, we have become the experts in our niche and in doing so we have been able to provide the best tools for our customers. Most importantly, by being problem solvers we learned how to scale our business. Supporting tens of thousands of transactions monthly means we have to be on our A-Game, instilling process and accountability in all that we do. We facilitate the sale from beginning to end with the help of our state-of-the-art technology.',
            },
            {
                image: blurb2Image,
                title: 'The Hunt is On',
                content: <div>
                    The tools StorageTreasures provides Auction Buyers to be successful bidding and winning are superior and always evolving.
                    <div className="marginT10"> Check out some of the great tools we have today:</div>
                    <ul>
                        <li> Search by City, State, Zip, Facility, and Keyword</li>
                        <li> Filter by Auction Type or Category</li>
                        <li> Auction Notifications for units near you</li>
                        <li>Customer Support 6 days a week</li>
                        <li>Help Center, Webinars, Videos, and Articles</li>
                        <li>Professional Blog to gather expert knowledge</li>
                    </ul>
                </div>,
            },
            {
                image: blurb3Image,
                title: 'We Are a Team',
                content: 'Our passion is contagious. We love what we do and it shows everyday with the amount of effort our team puts into making sure this is the best experience possible for our users, both Buyers and Facility Managers alike. We use your feedback to  improve processes and solve industry problems. Our team is always available to hear what you have to say and we encourage you to be a part of our evolution!',
            },
        ],

        search_obj: {
            type: 'zipcode',
            radius: '100',
            term: '',
            term_state: '',
        },

        search_obj_default: {
            type: 'zipcode',
            radius: '100',
            term: '',
            term_state: '',
        },

        doRedirect: null,
        timeout: null,
    };

    isMounted = false;


    componentDidMount() {

        this.isMounted = true;
        this.getImage();

        this.props.triggerHomepageApis(false, 6);

        this.handleCalls().then(/*nothing to do here*/);

        window.addEventListener('resize', this.getImage);
    }

    componentWillUnmount = () => {
        this.isMounted = false;
        trigger_cancel('auctions.getTopAuction');
        trigger_cancel('auctions.getAuctionsEndingSoon');

        // remove bindings
        this.state.timeout && clearTimeout(this.state.timeout);
        window.removeEventListener('resize', this.getImage);
        this.props.clearHomeAuctionData();


        // clear notifications on nav away
        this.props.getMustKnowNotifications(undefined, { clear: true });
    };

    handleCalls = async () => {
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }

        // Fire both events at the same time and wait for both to resolve
        await Promise.all([
            this.props.getTopAuctions(true, 6),
            this.props.getAuctionsEndingSoon(true, 6),
        ]);

        // Trigger another 5 second tick if the component is mounted. this condition
        // exists because the component could be unmounted while the promise is waiting to be resolved.
        if (this.isMounted) {
            const timeout = setTimeout(this.handleCalls, 5000);
            this.setState({ timeout });
        }
    };

    /**
     * output the images...
     * */
    getImage = () => {
        switch (true) {
            case (window.innerWidth < 320):
                this.setState({ image: [su_xxs, mk_xxs] });
                break;
            case (window.innerWidth >= 320 && window.innerWidth < 480):
                this.setState({ image: [su_xs, mk_xs] });
                break;
            case (window.innerWidth >= 480 && window.innerWidth < 768):
                this.setState({ image: [su_sm, mk_sm] });
                break;
            case (window.innerWidth >= 768 && window.innerWidth < 992):
                this.setState({ image: [su_md, mk_md] });
                break;
            case (window.innerWidth >= 992 && window.innerWidth < 1200):
                this.setState({ image: [su_lg, mk_lg] });
                break;
            case (window.innerWidth >= 1200):
                this.setState({ image: [su_lg, mk_lg] });
                break;
            default:
                this.setState({ image: [su_lg, mk_lg] });
        }

        return this.state.image;
    };

    renderSelectAuctionTypeOptions = () => this.selectAuctionTypeOptions.map((item, index) => <option key={index} value={item.id}>{item.text}</option>);

    renderHowItWorksSM = () => (
        <div className="sub-container">
            <div className="d-none d-md-block marginB30">
                <h2 className="section-title">
            How It Works
                </h2>

                <div className="row works">
                    {this.state.howItWorks.map((how, index) => (
                        <div className="col-sm-6 col-lg-3 works-item text-center" key={index}>
                            <img src={how.image} alt={how.title} className="works-item-image" />
                            <h5 className="works-heading">{how.title}</h5>
                            <div className="works-body">{how.content}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );


    renderHowItWorksXS = () => (
        <div className="sub-container">
            <div className="d-md-none how-it-works marginB30">
                <h2 className="section-title">
            How It Works
                </h2>
                <Carousel name="how-it-works-carousel" height="100%" accentColor="primary" class="works with-icons-home" timeout="7000" innerClassName="carousel-inner-minvalue">
                    {this.state.howItWorks.map((how, index) => (
                        <div className="col-sm-3 works-item text-center" key={index}>
                            <img src={how.image} alt={how.title} className="works-item-image" />
                            <h5 className="do-for-you-title works-heading">{how.title}</h5>
                            <div className="works-body">{how.content}</div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );

    onChangeAuctionType = (event) => {
        const { value } = event.target;

        if (value == '2') {
            window.$('.auctions-ending-soon').fadeOut(250, () => {
                window.$('.top-auctions').fadeIn(250);
            });
        } else if (value == '1') {
            window.$('.top-auctions').fadeOut(250, () => {
                window.$('.auctions-ending-soon').fadeIn(250);
            });
        }

        this.setState({ selected_auction_type: value });
    };

    renderHappeningNow = () => {
        if (this.props.auction_loading) {
            return (
                <Loading loading_component={[{ label: 'Loading', loading: this.props.auction_loading }]} />
            );
        }

        return (
            <div className="happening-now text-center">
                <div className="sub-container happening-now-content">
                    <h2 className="section-title text-center"> Happening Now</h2>

                    <div className="row">
                        <select
                            className="select auctiontype-select"
                            name="active-auctions"
                            id="active-auctions-dropdown"
                            value={this.state.selected_auction_type}
                            onChange={this.onChangeAuctionType}
                        >
                            {this.renderSelectAuctionTypeOptions()}
                        </select>
                    </div>
                    <div className="auctions-container">
                        <div className="top-auctions" style={{ display: 'none' }}>

                            <div className="auctions-container">
                                <div className="auction-tiles-special-center">
                                    {this.props.top_auctions && this.props.top_auctions.map((auction, i) => (
                                        <AuctionTile auction={auction} vertical="true" showContents="false" key={i} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="auctions-ending-soon">

                            <div className="auctions-container">
                                <div className="auction-tiles-special-center">
                                    {this.props.auctions_ending_soon && this.props.auctions_ending_soon.map((auction, i) => (
                                        <AuctionTile auction={auction} vertical="true" showContents="false" key={i} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"/>
                    <Link id="find-more-auctions"
                        to="/auctions"
                        className="btn btn-blue btn-block btn-regular find-more-auctions">Find More
                    Auctions</Link>
                </div>
            </div>
        );
    };

    renderCustomers = () => (
        <div className="customers-saying">
            <h2 className="customers-title text-center">
            What Our Customers Are Saying
            </h2>
            {/* homepage template_id */}
            <TrustPilot template_id="539ad0ffdec7e10e686debd7" height="300px" />
            <hr />
        </div>
    );

    renderWhyStorageTreasures = () => (
        <div className="why-storagetreasures">
            <h2 className="section-title text-center"> Why StorageTreasures? </h2>
            <div className="sub-container">
                <Blurb blurbs={this.state.blurbs} />
            </div>
        </div>
    );

    renderReadyToStart = () => (
        <div className="hero home-hero text-center">
            <div className="hero-content ready-to-start-body">
                <div className="ready-to-start-title">Ready to get started?</div>
                <Link to="/register" className="btn btn-orange btn-regular" id="homepage-sign-up" title="Sign Up">Sign Up</Link>
            </div>
            <div style={{ background: '#094464' }} className="ready-to-start-background" />
            <img src={this.state.image[0]} alt="Ready to start" className="ready-to-start-image" />
        </div>
    );

    doSearch = ({ search_obj }) => {
        let url='/auctions';
        switch(search_obj?.type){
            case 'city' :
                search_obj.term_city_label=search_obj.term_city;
                search_obj.term_state=search_obj?.term_city.split(',')[1]?.trim()?.toLowerCase() || '';
                search_obj={
                    ...search_obj,
                    page_count: 15,
                    page_num :1,
                    sort_column :'expire_date',
                    sort_column_index :1,
                    sort_direction :'asc',
                };
                if(typeof search_obj.term_city != 'undefined'){
                    url+=`/${search_obj.term_state}/${search_obj.term_city.split(',')[0].trim().toLowerCase()}`;
                }
                break;
            case 'state':
                search_obj.term_state= search_obj?.term_state.trim().toLowerCase() ||'';
                search_obj={
                    ...search_obj,
                    page_count: 15,
                    page_num :1,
                    sort_column :'expire_date',
                    sort_column_index :1,
                    sort_direction :'asc',
                };
                url+=`/${search_obj.term_state}`;
                break;
            case 'zipcode' :
                search_obj={
                    ...search_obj,
                    page_count: 15,
                    page_num :1,
                    radius : search_obj.radius,
                    sort_column :'expire_date',
                    sort_column_index :1,
                    sort_direction :'asc',
                };
                break;
            default:
                break;
        }

        localStorage.setItem('search_obj',
            JSON.stringify({
                ...JSON.parse(localStorage.getItem('search_obj')),
                ...search_obj,
                page_count: 15,
                page_num :1,
                sort_column :'expire_date',
                sort_column_index :1,
                sort_direction :'asc',
            }),
        );
        Router.push(url);
    };

    search_obj_update = ({ target: { name, value } }) => this.setState({ search_obj: { ...this.state.search_obj, [name]: value } });

    render() {
        const { doRedirect } = this.state;

        if (doRedirect) {
            return <DoRedirect to={doRedirect} />;
        }

        return (
            <div id="homepage">
                <HomepageSchema />
                <AuctionSearchSchema />
                <AppPromoHero>
                    <TopSearch
                        search_obj={this.state.search_obj}
                        search_obj_updated={this.search_obj_update}
                        redoSearch={this.doSearch}
                        className="font20px"
                    />
                </AppPromoHero>
                <div className="homepage__orange-banner">
                    <p>Join over 375,000 bidders in discovering storage auctions from more than 20,000 facilities</p>
                </div>
                <section className="text-center">
                    {this.renderHowItWorksSM()}
                    {this.renderHowItWorksXS()}
                </section>

                {this.renderHappeningNow()}
                <MustKnow notifications={this.props.notifications} notification_loading={this.props.notification_loading} images={this.state.carouselBackground} />
                {this.renderCustomers()}
                {this.renderWhyStorageTreasures()}
                {this.renderReadyToStart()}
            </div>
        );
    }
}

const mapStateToProps = ({ auction, notification }) => ({
    auction_loading: auction.loading,
    top_auctions: auction.top_auctions,
    auctions_ending_soon: auction.auctions_ending_soon,
    notifications: notification.notifications,
    notification_loading: notification.loading,
    error: auction.error,
    searchTypes: auction.searchTypes,
});

const calls = {
    getTopAuctions,
    getAuctionsEndingSoon,
    getMustKnowNotification,
    getMustKnowNotifications,
    triggerHomepageApis,
    searchLiveAuctions,
    clearHomeAuctionData,
};

export default connect(mapStateToProps, calls)(Index);