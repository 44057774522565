import React from 'react';
import 'styles/components/Blurb.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface BlurbProps {
    blurbs: {
        image: string;
        title: string;
        content: string;
    }[];
}

const Blurb: React.FC<BlurbProps> = (props) => {
    return (
        <div className="blurb">
            <Row>
                <Col className="p-0" xs={12} lg={6}>
                    <img src={props.blurbs[0].image} alt={props.blurbs[0].title} className="w-100" />
                </Col>
                <Col className="d-flex flex-column justify-content-center blurb-content" xs={12} lg={6}>
                    <span className="blurb-header">{props.blurbs[0].title}</span>
                    <div className="blurb-body">{props.blurbs[0].content}</div>
                </Col>
            </Row>
            <Row>
                <Col className="p-0" xs={12} lg={{ span: 6, order: 2 }}>
                    <img src={props.blurbs[1].image} alt={props.blurbs[1].title} className="w-100" />
                </Col>
                <Col className="d-flex flex-column justify-content-center blurb-content" xs={12} lg={{ span: 6, order: 1 }}>
                    <span className="blurb-header">{props.blurbs[1].title}</span>
                    <div className="blurb-body">{props.blurbs[1].content}</div>
                </Col>
            </Row>
            <Row>
                <Col className="p-0" xs={12} lg={6}>
                    <img src={props.blurbs[2].image} alt={props.blurbs[2].title} className="w-100" />
                </Col>
                <Col className="d-flex flex-column justify-content-center blurb-content" xs={12} lg={6}>
                    <span className="blurb-header">{props.blurbs[2].title}</span>
                    <div className="blurb-body">{props.blurbs[2].content}</div>
                </Col>
            </Row>
        </div>
    );
};

export default Blurb;
